/*
This file is generated, please do not edit this file.
The icons in this file are generated from the .svg files inside public/images/icons.

You can regenerate this file by running `pnpm codegen:icons`.
*/
export const iconNames = [
 "add-cart",
 "adjustments",
 "air",
 "alert-circle",
 "announcement-01",
 "arrow-down",
 "arrow-left",
 "arrow-right",
 "arrow-square-down",
 "arrow-up",
 "arrow-up-circle",
 "automotive",
 "battery",
 "bleaching-allowed",
 "bookmark-alt",
 "box-outline",
 "box-solid",
 "building-02",
 "building-08",
 "business",
 "calendar",
 "careers",
 "cart",
 "chat-big",
 "chat-history",
 "check",
 "check-circle",
 "check-misc",
 "chevron-down",
 "chevron-left",
 "chevron-right",
 "chevron-up",
 "clock",
 "construction",
 "cube",
 "customer-service",
 "decrease",
 "diamond",
 "do-not-bleach",
 "do-not-bleach-with-chlorine",
 "do-not-iron",
 "do-not-tumble-dry",
 "do-not-wash",
 "drill",
 "dry-at-normal-temperature",
 "dry-cleaning-allowed",
 "dry-flat",
 "eye",
 "eye-off",
 "filter",
 "flag",
 "flag-05",
 "garden",
 "gently-wash-at-30c-anti-crease-program",
 "gently-wash-at-40c-anti-crease-program",
 "gently-wash-at-60c-anti-crease-program",
 "gift",
 "glue",
 "grid-01",
 "hand-wash",
 "heart-outline",
 "heart-solid",
 "home",
 "home-line",
 "increase",
 "info",
 "info-circle",
 "invoices",
 "iron-up-to-a-maximum-of-110c",
 "iron-up-to-a-maximum-of-150c",
 "iron-up-to-a-maximum-of-200c",
 "layout-alt-04",
 "lock-closed",
 "lock-wide",
 "login",
 "logout",
 "low-temperature-drying-only",
 "mail",
 "mail-big",
 "mark",
 "menu",
 "menu-alt-4",
 "message-question-circle",
 "metal",
 "mild-chermical-cleaning-with-perchlorethylene-allowed",
 "minus",
 "minus-circle",
 "not-suitable-for-dry-cleaning",
 "package",
 "phone-big",
 "pie-chart-03",
 "play",
 "plus",
 "plus-circle",
 "power",
 "question-mark",
 "receipt",
 "reply",
 "reset",
 "returns",
 "returns-big",
 "review-star",
 "ruler",
 "sandpaper",
 "saw",
 "search",
 "search-md",
 "search-sm",
 "settings-04",
 "shopping-bag",
 "shopping-cart",
 "smiley-1",
 "smiley-2",
 "smiley-3",
 "smiley-4",
 "smiley-5",
 "star",
 "stars-02",
 "store-outline",
 "tag",
 "thumb-down",
 "thumb-up",
 "time-circle",
 "toolcart",
 "tools",
 "trash",
 "trash-01",
 "truck",
 "tumble-dry-allowed",
 "user",
 "user-add",
 "user-logged-in",
 "warning",
 "warning-circle",
 "wash-at-30c",
 "wash-at-40c",
 "wash-at-60c",
 "wash-at-90c",
 "wash-with-extra-care-at-30c-wool-wash-program",
 "wash-with-extra-care-at-40c-wool-wash-program",
 "weight",
 "x",
 "x-circle",
 "x-close",
] as const;

export type IconName = typeof iconNames[number];

export const iconMasks = {
	"add-cart": "icon-add-cart",
	"adjustments": "icon-adjustments",
	"air": "icon-air",
	"alert-circle": "icon-alert-circle",
	"announcement-01": "icon-announcement-01",
	"arrow-down": "icon-arrow-down",
	"arrow-left": "icon-arrow-left",
	"arrow-right": "icon-arrow-right",
	"arrow-square-down": "icon-arrow-square-down",
	"arrow-up": "icon-arrow-up",
	"arrow-up-circle": "icon-arrow-up-circle",
	"automotive": "icon-automotive",
	"battery": "icon-battery",
	"bleaching-allowed": "icon-bleaching-allowed",
	"bookmark-alt": "icon-bookmark-alt",
	"box-outline": "icon-box-outline",
	"box-solid": "icon-box-solid",
	"building-02": "icon-building-02",
	"building-08": "icon-building-08",
	"business": "icon-business",
	"calendar": "icon-calendar",
	"careers": "icon-careers",
	"cart": "icon-cart",
	"chat-big": "icon-chat-big",
	"chat-history": "icon-chat-history",
	"check": "icon-check",
	"check-circle": "icon-check-circle",
	"check-misc": "icon-check-misc",
	"chevron-down": "icon-chevron-down",
	"chevron-left": "icon-chevron-left",
	"chevron-right": "icon-chevron-right",
	"chevron-up": "icon-chevron-up",
	"clock": "icon-clock",
	"construction": "icon-construction",
	"cube": "icon-cube",
	"customer-service": "icon-customer-service",
	"decrease": "icon-decrease",
	"diamond": "icon-diamond",
	"do-not-bleach": "icon-do-not-bleach",
	"do-not-bleach-with-chlorine": "icon-do-not-bleach-with-chlorine",
	"do-not-iron": "icon-do-not-iron",
	"do-not-tumble-dry": "icon-do-not-tumble-dry",
	"do-not-wash": "icon-do-not-wash",
	"drill": "icon-drill",
	"dry-at-normal-temperature": "icon-dry-at-normal-temperature",
	"dry-cleaning-allowed": "icon-dry-cleaning-allowed",
	"dry-flat": "icon-dry-flat",
	"eye": "icon-eye",
	"eye-off": "icon-eye-off",
	"filter": "icon-filter",
	"flag": "icon-flag",
	"flag-05": "icon-flag-05",
	"garden": "icon-garden",
	"gently-wash-at-30c-anti-crease-program": "icon-gently-wash-at-30c-anti-crease-program",
	"gently-wash-at-40c-anti-crease-program": "icon-gently-wash-at-40c-anti-crease-program",
	"gently-wash-at-60c-anti-crease-program": "icon-gently-wash-at-60c-anti-crease-program",
	"gift": "icon-gift",
	"glue": "icon-glue",
	"grid-01": "icon-grid-01",
	"hand-wash": "icon-hand-wash",
	"heart-outline": "icon-heart-outline",
	"heart-solid": "icon-heart-solid",
	"home": "icon-home",
	"home-line": "icon-home-line",
	"increase": "icon-increase",
	"info": "icon-info",
	"info-circle": "icon-info-circle",
	"invoices": "icon-invoices",
	"iron-up-to-a-maximum-of-110c": "icon-iron-up-to-a-maximum-of-110c",
	"iron-up-to-a-maximum-of-150c": "icon-iron-up-to-a-maximum-of-150c",
	"iron-up-to-a-maximum-of-200c": "icon-iron-up-to-a-maximum-of-200c",
	"layout-alt-04": "icon-layout-alt-04",
	"lock-closed": "icon-lock-closed",
	"lock-wide": "icon-lock-wide",
	"login": "icon-login",
	"logout": "icon-logout",
	"low-temperature-drying-only": "icon-low-temperature-drying-only",
	"mail": "icon-mail",
	"mail-big": "icon-mail-big",
	"mark": "icon-mark",
	"menu": "icon-menu",
	"menu-alt-4": "icon-menu-alt-4",
	"message-question-circle": "icon-message-question-circle",
	"metal": "icon-metal",
	"mild-chermical-cleaning-with-perchlorethylene-allowed": "icon-mild-chermical-cleaning-with-perchlorethylene-allowed",
	"minus": "icon-minus",
	"minus-circle": "icon-minus-circle",
	"not-suitable-for-dry-cleaning": "icon-not-suitable-for-dry-cleaning",
	"package": "icon-package",
	"phone-big": "icon-phone-big",
	"pie-chart-03": "icon-pie-chart-03",
	"play": "icon-play",
	"plus": "icon-plus",
	"plus-circle": "icon-plus-circle",
	"power": "icon-power",
	"question-mark": "icon-question-mark",
	"receipt": "icon-receipt",
	"reply": "icon-reply",
	"reset": "icon-reset",
	"returns": "icon-returns",
	"returns-big": "icon-returns-big",
	"review-star": "icon-review-star",
	"ruler": "icon-ruler",
	"sandpaper": "icon-sandpaper",
	"saw": "icon-saw",
	"search": "icon-search",
	"search-md": "icon-search-md",
	"search-sm": "icon-search-sm",
	"settings-04": "icon-settings-04",
	"shopping-bag": "icon-shopping-bag",
	"shopping-cart": "icon-shopping-cart",
	"smiley-1": "icon-smiley-1",
	"smiley-2": "icon-smiley-2",
	"smiley-3": "icon-smiley-3",
	"smiley-4": "icon-smiley-4",
	"smiley-5": "icon-smiley-5",
	"star": "icon-star",
	"stars-02": "icon-stars-02",
	"store-outline": "icon-store-outline",
	"tag": "icon-tag",
	"thumb-down": "icon-thumb-down",
	"thumb-up": "icon-thumb-up",
	"time-circle": "icon-time-circle",
	"toolcart": "icon-toolcart",
	"tools": "icon-tools",
	"trash": "icon-trash",
	"trash-01": "icon-trash-01",
	"truck": "icon-truck",
	"tumble-dry-allowed": "icon-tumble-dry-allowed",
	"user": "icon-user",
	"user-add": "icon-user-add",
	"user-logged-in": "icon-user-logged-in",
	"warning": "icon-warning",
	"warning-circle": "icon-warning-circle",
	"wash-at-30c": "icon-wash-at-30c",
	"wash-at-40c": "icon-wash-at-40c",
	"wash-at-60c": "icon-wash-at-60c",
	"wash-at-90c": "icon-wash-at-90c",
	"wash-with-extra-care-at-30c-wool-wash-program": "icon-wash-with-extra-care-at-30c-wool-wash-program",
	"wash-with-extra-care-at-40c-wool-wash-program": "icon-wash-with-extra-care-at-40c-wool-wash-program",
	"weight": "icon-weight",
	"x": "icon-x",
	"x-circle": "icon-x-circle",
	"x-close": "icon-x-close",
};
