"use client";

import { CustomErrorKeys } from "@commerce-shared/validation";
import type { Formats, TranslationValues } from "next-intl";
import { useEffect } from "react";
import { z } from "zod";
import type labels from "./FormError.labels.json";

export type FormErrorLabels = typeof labels;

export type Translations = (
	key: string,
	values?: TranslationValues,
	formats?: Partial<Formats>,
) => string;

export const useFormErrors = (formErrorTranslations: Translations) => {
	useEffect(() => {
		z.setErrorMap((issue) => issueToError(issue, formErrorTranslations));
	}, [formErrorTranslations]);
};

export const issueToError = (
	issue: z.ZodIssueOptionalMessage,
	formErrorTranslations: Translations,
): { message: string } => {
	const t = formErrorTranslations;

	switch (issue.code) {
		// Basic error codes from Zod
		case "invalid_string":
			switch (issue.validation) {
				case "datetime":
					return {
						message: t("required"),
					};

				case "email":
					return {
						message: t("invalid-email"),
					};

				default:
					return {
						message: t("invalid"),
					};
			}
		case "invalid_date":
			return {
				message: t("invalid"),
			};
		case "invalid_literal":
			return {
				message: t("invalid"),
			};
		case "invalid_enum_value":
			return {
				message: t("invalid"),
			};
		case "invalid_type":
			return {
				message: t("invalid"),
			};
		case "too_big":
			switch (issue.type) {
				case "number":
				case "bigint":
					return {
						message: t("number-too-big", {
							value: issue.maximum.toString(),
						}),
					};
				default:
					return {
						message: t("string-too-long", {
							value: issue.maximum.toString(),
						}),
					};
			}
		case "too_small":
			switch (issue.type) {
				case "number":
				case "bigint":
					return {
						message: t("number-too-small", {
							value: issue.minimum.toString(),
						}),
					};
				default:
					return {
						message:
							issue.minimum === 1
								? t("required")
								: t("string-too-short", {
										value: issue.minimum.toString(),
									}),
					};
			}
		// Non-default Zod errors
		case "unrecognized_keys":
			switch (issue.keys[0]) {
				case CustomErrorKeys.InvalidPhoneNumber:
					return { message: t("invalid-phone-number") };
				case CustomErrorKeys.WeakPassword:
					return { message: t("password-too-weak") };
				case CustomErrorKeys.PasswordsCantMatch:
					return { message: t("passwords-cant-match") };
				case CustomErrorKeys.PasswordMissingSpecialCharacter:
					return { message: t("no-special-character") };
				case CustomErrorKeys.PasswordMissingUppercase:
					return { message: t("no-upper-case") };
				case CustomErrorKeys.CompanyNameMissing:
					return { message: t("company-name-missing") };
				case CustomErrorKeys.InvalidPostalCode:
					return {
						message: t("invalid-postalcode"),
					};
				case CustomErrorKeys.InvalidHouseNumber:
					return {
						message: t("invalid-house-number"),
					};
				case CustomErrorKeys.InvalidFingerprint:
					return { message: t("invalid-fingerprint") };
				case CustomErrorKeys.InvalidCreditCardBrand:
					return { message: t("invalid-credit-card-brand") };
				default:
					return { message: t("invalid") };
			}
		// Server errors
		case "custom":
			switch (issue.message) {
				case CustomErrorKeys.CustomerAlreadyExists:
					return { message: t("customer-already-exists") };
				case CustomErrorKeys.InvalidCredentials:
					return { message: t("incorrect-credentials") };
				case CustomErrorKeys.RequestThrottled:
					return { message: t("request-throttled") };
				case CustomErrorKeys.InvalidCurrentPassword:
					return { message: t("invalid-current-password") };
				default:
					return { message: t("generic-server-error") };
			}
	}

	return {
		message: t(issue.code).includes(issue.code) ? t("invalid") : t(issue.code),
	};
};
