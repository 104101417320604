import type { GraphQLError } from "@labdigital/graphql-fetcher";
import type { ZodError } from "zod";

export class ServerError extends Error {
	readonly errors: Readonly<GraphQLWithZodError[]>;

	constructor(errors: GraphQLWithZodError[]) {
		super("GraphQL server error");
		this.errors = errors;
	}
}

/**
 * Helper function that handles error throwing for GraphQL errors coming from the server
 * @returns
 */
export const throwErrors = (errors: GraphQLError[] | null): undefined => {
	if (errors?.length) {
		throw new ServerError(errors);
	}
	return undefined;
};

export type GraphQLWithZodError = {
	message: string;
	extensions: {
		code: string;
		errorData?: Record<string, unknown>;
		errorCode?: string;
		serviceName: string;
		message?: string;
		exception?: { message: string };
		errors?: ZodError;
	};
};
