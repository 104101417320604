import type { ZodIssue } from "zod";
import { ZodIssueCode } from "zod";

export const CustomErrorKeys = {
	InvalidPhoneNumber: "INVALID_PHONE_NUMBER",
	WeakPassword: "WEAK_PASSWORD",
	PasswordsCantMatch: "PASSWORDS_CANT_MATCH",
	PasswordMissingUppercase: "PASSWORD_MISSING_UPPERCASE",
	PasswordMissingSpecialCharacter: "PASSWORD_MISSING_SPECIAL_CHARACTER",
	CompanyNameMissing: "COMPANY_NAME_MISSING",
	InvalidPostalCode: "INVALID_POSTAL_CODE",
	InvalidPostalCodeHouseNumber: "INVALID_POSTAL_CODE_HOUSE_NUMBER",
	InvalidHouseNumber: "INVALID_HOUSE_NUMBER",
	InvalidDateOfBirth: "INVALID_DATE_OF_BIRTH",
	CustomerAlreadyExists: "CUSTOMER_ALREADY_EXISTS",
	InvalidCredentials: "INVALID_CREDENTIALS",
	PasswordResetMigratedCustomer: "PASSWORD_RESET_MIGRATED_CUSTOMER",
	InvalidCurrentPassword: "INVALID_CURRENT_PASSWORD",
	InvalidAccountType: "INVALID_ACCOUNT_TYPE",
	RequestThrottled: "REQUEST_THROTTLED",
	InvalidFingerprint: "INVALID_FINGERPRINT",
	InvalidCreditCardBrand: "INVALID_CREDIT_CARD_BRAND",
} as const;

/**
 * @param messageKey The key used to output a custom error message
 * @param path The property path of the json input. E.g. `["address", "postalCode"]`.
 * If not given, this will be considered a global input issue.
 */
export const customZodIssue = (
	messageKey: keyof typeof CustomErrorKeys,
	path?: string[],
): ZodIssue => ({
	code: ZodIssueCode.custom,
	path: path ?? [],
	params: { code: CustomErrorKeys[messageKey] },
	message: "",
});

export const requiredZodIssue = (path?: string[]): ZodIssue => ({
	code: ZodIssueCode.too_small,
	minimum: 1,
	type: "string",
	inclusive: true,
	path: path ?? [],
	message: "",
});

export type CustomIssue = {
	code: "custom";
	path?: string[];
	params: { code: (typeof CustomErrorKeys)[keyof typeof CustomErrorKeys] };
};
