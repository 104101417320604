import { type Country, countries, salutations } from "@commerce-shared/site-config";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { ZodIssueCode, z } from "zod";
import { CustomErrorKeys, requiredZodIssue } from "./errorKeys";
import { transformSalutation } from "./transformers";

// Generic fields

export const emailField = z.string().email().min(2).max(50).toLowerCase();

export const passwordField = z.string().min(8).max(64);

export const passwordChangeField = passwordField.superRefine((password, ctx) => {
	const uppercaseCount = (password.match(/[A-Z]/g) || []).length;
	const specialCharCount = (password.match(/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g) || []).length;

	if (uppercaseCount < 1) {
		ctx.addIssue({
			keys: [CustomErrorKeys.PasswordMissingUppercase],
			code: ZodIssueCode.unrecognized_keys,
		});
	}

	if (specialCharCount < 1) {
		ctx.addIssue({
			keys: [CustomErrorKeys.PasswordMissingSpecialCharacter],
			code: ZodIssueCode.unrecognized_keys,
		});
	}
});
export const salutationSchema = z.enum(salutations).transform(transformSalutation);

const dateOfBirthSchema = z
	.object({
		day: z.number({ coerce: true }).min(1).max(31),
		month: z.number({ coerce: true }).min(1).max(12),
		year: z.number({ coerce: true }).min(1900).max(new Date().getFullYear()),
	})
	.superRefine((val, ctx) => {
		const { day, month, year } = val;
		const dayPadded = String(day).padStart(2, "0");
		const monthPadded = String(month).padStart(2, "0");
		const isValidDate = !Number.isNaN(new Date(`${year}-${monthPadded}-${dayPadded}`).getTime());

		if (!isValidDate) {
			ctx.addIssue(requiredZodIssue(["dateOfBirth"]));
		}
	})
	.transform(({ day, month, year }) => {
		return `${year}-${month}-${day}`;
	});

/**
 * Schema used to update a user's email
 * Password is required to authorize the change, with a more sophisticated flow we could replace password with a confirmation email
 */
export const updateEmailSchema = z.object({
	email: emailField,
	password: passwordField,
});

// 0) Account Login

export const accountSearchSchema = z.object({
	email: z.string().email().min(2).max(50).toLowerCase(),
});

export const accountLoginSchema = z.object({
	email: emailField,
	password: passwordField,
});

export const phoneSchema = z
	.object({
		country: z.enum(countries),
		phone: z.string().min(1).max(20),
	})
	.transform((data, ctx) => {
		if (!countries.includes(data.country)) {
			// Don't transform when invalid country code given
			return z.NEVER;
		}
		if (!isValidPhoneNumber(data.phone, data.country)) {
			// Validate phone number validity
			ctx.addIssue({
				keys: [CustomErrorKeys.InvalidPhoneNumber],
				path: ["phone"],
				code: ZodIssueCode.unrecognized_keys,
			});

			return z.NEVER;
		}

		return {
			...data,
			phone: parsePhoneNumber(data.phone, data.country).format("E.164"),
		};
	});

export const accountPasswordSchema = z.object({
	password: passwordField,
	newPassword: passwordChangeField,
});

export const companySchema = z.object({
	accountType: z.literal("business"),
	company: z.string().min(1).max(150),
	vatNumber: z.string().min(1).max(150),
});

const PostCodeRegexes: Record<Country, RegExp> = {
	BE: /^\d{4}$/i,
	DE: /^\d{5}$/i,
	FR: /^\d{5}$/i,
	NL: /^([1-9][0-9]{3})\s*([a-z]{2})$/i,
	LU: /^\d{4}$/i,
	AT: /^\d{4}$/i,
	ES: /^\d{5}$/i,
	PT: /^\d{4}[- ]\d{3}$/i,
};

const baseAddressSchema = z.object({
	salutation: salutationSchema.optional().nullable(),
	firstName: z.string().trim().min(2).max(50),
	lastName: z.string().trim().min(2).max(50),
	country: z.enum(countries),
	street: z.string().min(1).max(150),
	houseNumber: z.string().max(50),
	houseNumberSuffix: z.string().max(50).optional(),
	postalCode: z.string().min(1).max(50),
	city: z.string().min(1).max(50),
});

const validatePostalCode = <T extends { country: Country; postalCode: string }>(
	data: T,
	ctx: z.RefinementCtx,
): T => {
	if (!countries.includes(data.country)) {
		// Don't transform when invalid country code given
		return z.NEVER;
	}

	// Validate postal code validity
	const regex = PostCodeRegexes[data.country];
	if (!regex.test(data.postalCode)) {
		ctx.addIssue({
			path: ["postalCode"],
			keys: [CustomErrorKeys.InvalidPostalCode],
			code: ZodIssueCode.unrecognized_keys,
		});

		return z.NEVER;
	}

	return data;
};

const validateHouseNumber = <T extends { country: Country; houseNumber: string }>(
	data: T,
	ctx: z.RefinementCtx,
): T => {
	if (!countries.includes(data.country)) {
		// Don't transform when invalid country code given
		return z.NEVER;
	}

	if (data.houseNumber.length === 0) {
		ctx.addIssue({
			path: ["houseNumber"],
			code: ZodIssueCode.too_small,
			type: "string",
			minimum: 1,
			inclusive: true,
		});
		return z.NEVER;
	}

	// Validate house number is a positive integer
	const positiveIntRegex = /^[1-9]\d*$/;
	if (data.houseNumber.length > 0 && !positiveIntRegex.test(data.houseNumber)) {
		ctx.addIssue({
			path: ["houseNumber"],
			keys: [CustomErrorKeys.InvalidHouseNumber],
			code: ZodIssueCode.unrecognized_keys,
		});

		return z.NEVER;
	}

	return data;
};

export const addressSchema = baseAddressSchema
	.transform(validatePostalCode)
	.superRefine(validateHouseNumber);

const ctAddressSchema = baseAddressSchema
	.pick({
		firstName: true,
		lastName: true,
		postalCode: true,
		city: true,
		country: true,
	})
	.extend({
		streetName: z.string().min(1).max(150),
	});

export const ctAddressHomeSchema = ctAddressSchema
	.extend({
		streetNumber: z.string().min(1).max(50),
	})
	.transform(validatePostalCode);

export const ctAddressPickupSchema = ctAddressSchema
	.extend({
		streetNumber: z.string().min(1).max(50).optional(),
	})
	.transform(validatePostalCode);

export const contactSchema = z.object({
	salutation: salutationSchema.optional().nullable(),
	firstName: z.string().trim().min(1).max(50),
	lastName: z.string().trim().min(1).max(50),
});

export const customerAddressSchema = contactSchema
	.and(addressSchema)
	.and(phoneSchema)
	.and(z.object({ email: emailField.optional() }))
	.and(z.object({ accountType: z.literal("personal") }).or(companySchema));

// 2) Shipping

export const sameAsBillingDeliverySchema = z.object({
	deliveryType: z.literal("sameAsBillingAddress"),
});

export const pickupDeliverySchema = z.object({
	deliveryType: z.literal("pickupAtShop"),
});

export const baseDoorDeliverySchema = z
	.object({
		deliveryType: z.literal("doorDelivery"),
		notes: z.string().default("").optional(),
	})
	.merge(baseAddressSchema);

export const doorDeliverySchema = baseDoorDeliverySchema
	.transform(validatePostalCode)
	.superRefine(validateHouseNumber);

export const deliverySchema = z
	.discriminatedUnion("deliveryType", [
		sameAsBillingDeliverySchema,
		baseDoorDeliverySchema,
		pickupDeliverySchema,
	])
	.transform((data, ctx) => {
		if (data.deliveryType === "doorDelivery") {
			validateHouseNumber(data, ctx);
			return validatePostalCode(data, ctx);
		}

		return data;
	});

export const creditCardPaymentSchema = z.object({
	paymentMethod: z.literal("adyen_scheme"),
	brand: z.preprocess(
		(input) => {
			if (input === undefined || input === null) {
				return "";
			}
			return input;
		},
		z.string().superRefine((value, ctx) => {
			if (!value.length) {
				ctx.addIssue({
					keys: [CustomErrorKeys.InvalidCreditCardBrand],
					code: ZodIssueCode.unrecognized_keys,
				});
			}
		}),
	),
	encryptedCardNumber: z.string(),
	encryptedExpiryMonth: z.string(),
	encryptedExpiryYear: z.string(),
	encryptedSecurityCode: z.string().optional(),
});

const paymentTermsSchema = z
	.boolean()
	.default(false)
	.superRefine((value, ctx) => {
		if (value !== true) {
			ctx.addIssue(requiredZodIssue(["paymentTerms"]));
		}
	});

const deviceFingerprintSchema = z.string().superRefine((value, ctx) => {
	if (!value.length) {
		ctx.addIssue({
			keys: [CustomErrorKeys.InvalidFingerprint],
			code: ZodIssueCode.unrecognized_keys,
		});
	}
});

const afterPaySchema = z.object({
	paymentMethod: z.literal("afterpay_default"),
	dateOfBirth: dateOfBirthSchema,
	paymentTerms: paymentTermsSchema,
	deviceFingerprint: deviceFingerprintSchema,
});

// 3) Payment
export const paymentSchema = z.discriminatedUnion("paymentMethod", [
	creditCardPaymentSchema
		.omit({ paymentMethod: true })
		.merge(
			z.object({
				paymentMethod: z.literal("scheme"),
			}),
		)
		.passthrough(),
	afterPaySchema.passthrough(),
	/**
	 * klarna = pay later
	 * klarna_account = pay over time
	 */
	z
		.object({
			paymentMethod: z.enum([
				"ideal",
				"applepay",
				"klarna_account",
				"klarna",
				"bcmc_mobile",
				"paypal",
			]),
		})
		.passthrough(),
]);

export const paymentFormSchema = z.discriminatedUnion("paymentMethod", [
	z.object({
		paymentMethod: z.literal("adyen_afterpay_default"),
		dateOfBirth: dateOfBirthSchema,
		paymentTerms: paymentTermsSchema,
		deviceFingerprint: deviceFingerprintSchema,
	}),
	creditCardPaymentSchema.passthrough(),
	z
		.object({
			paymentMethod: z.enum([
				"adyen_ideal",
				"adyen_klarna_account",
				"adyen_klarna",
				"adyen_bcmc_mobile",
				"adyen_paypal",
			]),
		})
		.passthrough(),
]);

// paymentFormSchema without additional information provided by adyen for credit cards
export const paymentFormFieldsSchema = z.discriminatedUnion("paymentMethod", [
	z.object({
		paymentMethod: z.literal("adyen_ideal"),
	}),
	z.object({
		paymentMethod: z.literal("adyen_afterpay_default"),
		dateOfBirth: dateOfBirthSchema,
		paymentTerms: paymentTermsSchema,
		deviceFingerprint: deviceFingerprintSchema,
	}),
	z.object({
		paymentMethod: z.literal("adyen_scheme"),
	}),
	z
		.object({
			paymentMethod: z.enum([
				"adyen_klarna_account",
				"adyen_klarna",
				"adyen_bcmc_mobile",
				"adyen_paypal",
			]),
		})
		.passthrough(),
]);

// 4) Additional

export const newsletterSchema = z.object({
	email: z.string().email().min(2).max(50).toLowerCase(),
});

export const accountDeleteSchema = z
	.object({
		password: passwordField,
	})
	.superRefine((val, ctx) => {
		if (!val.password.length) {
			// VAT number is required
			ctx.addIssue(requiredZodIssue(["password"]));
		}
	});

export const updateCustomerInformationSchema = z.object({
	address: customerAddressSchema,
});

export const passwordResetSchema = z.object({
	password: passwordChangeField,
});
export const accountPasswordUpdateSchema = accountPasswordSchema.superRefine((val, ctx) => {
	if (val.password === val.newPassword) {
		ctx.addIssue({
			keys: [CustomErrorKeys.PasswordsCantMatch],
			path: ["newPassword"],
			code: ZodIssueCode.unrecognized_keys,
		});
	}
});

export const registerFormSchema = z
	.object({
		email: emailField,
		password: passwordChangeField,
		newsletter: z.boolean().default(false).optional(),
	})
	.and(contactSchema)
	.and(
		z.object({ accountType: z.literal("personal") }).or(
			companySchema.and(
				z.object({
					vatNumber: z.string().trim().default(""),
				}),
			),
		),
	)
	.superRefine((val, ctx) => {
		if (!val.password.length) {
			ctx.addIssue(requiredZodIssue(["password"]));
		}
	});

export const stringToJSONSchema = z.string().transform((str) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return z.NEVER;
	}
});
