/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FormSubmissionEventData } from "@commerce-frontend/gtm-ecommerce";
import { usePathname } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";
import { useLocale, useTranslations } from "next-intl";
import type { HTMLAttributes, ReactNode } from "react";
import type { FieldValues, Path, UseFormHandleSubmit, UseFormReturn } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { ServerError } from "~/lib/helpers/errors";
import { useNavigation } from "~/lib/navigation-provider";
import {
	type DeepPromise,
	deviceData,
	locationDataFromPathname,
	sendGTMEvent,
	userData,
} from "~/lib/send-gtm-event";
import type { Translations } from "./useFormTranslations";
import { issueToError, useFormErrors } from "./useFormTranslations";

type Props<TValues extends FieldValues> = {
	form: UseFormReturn<TValues>;
	onSubmit: (values: TValues) => Promise<void> | void;
	children: ReactNode;
	type?: string;
	eventData?: () => DeepPromise<FormSubmissionEventData>;
} & Omit<HTMLAttributes<HTMLElement>, "onSubmit">;

export function Form<TValues extends FieldValues>({
	form,
	onSubmit,
	children,
	type,
	eventData,
	...attributes
}: Props<TValues>) {
	const formErrorTranslations = useTranslations("FormError");
	useFormErrors(formErrorTranslations);
	const pathname = usePathname();
	const { previousUrl } = useNavigation();
	const locale = useLocale() as Locale;

	return (
		<FormProvider {...form}>
			<form
				noValidate
				onSubmit={handleServerErrors(
					form,
					form.handleSubmit(onSubmit),
					formErrorTranslations,
					locale,
					pathname,
					previousUrl,
					type,
					eventData,
				)}
				{...attributes}
			>
				{children}
			</form>
		</FormProvider>
	);
}

/**
 * Wrapper function that catches any errors coming from the submitHandler and adds them to the error object on the form
 * This can then be used to display an error message to the user.
 */
const handleServerErrors =
	<TFieldValues extends FieldValues, TTransformedValues extends FieldValues = TFieldValues>(
		form: UseFormReturn<TFieldValues>,
		submitHandler: ReturnType<UseFormHandleSubmit<TFieldValues, TTransformedValues>>,
		errorT: Translations,
		locale: Locale,
		pathname: ReturnType<typeof usePathname>,
		previousUrl: string | null,
		type?: string,
		eventData?: () => DeepPromise<FormSubmissionEventData>,
	) =>
	async (e?: React.BaseSyntheticEvent) => {
		try {
			const result = await submitHandler(e);

			sendGTMEvent<FormSubmissionEventData>(
				eventData
					? eventData()
					: {
							event: "form_submission",
							timestamp: Date.now(),
							device: deviceData(),
							user: userData(),
							location: locationDataFromPathname({
								locale,
								pathname,
								previousUrl,
							}),
							general: {
								form_type: type ?? null,
							},
						},
			);

			return result;
		} catch (error) {
			// Server error can be a collection of GraphQL errors, for now we opt to just use the first one
			if (error instanceof ServerError) {
				handleGraphQLErrors(error, form, errorT);
				// form.setError("root", { message: `Error: ${error.errors[0].message}` });
			} else {
				form.setError("root", { message: errorT("server-error") });
			}
		}
	};

const handleGraphQLErrors = <T extends FieldValues>(
	error: ServerError,
	form: UseFormReturn<T>,
	formErrorTranslations: Translations,
	ignoreErrorCodes: string[] = [],
) => {
	let firstErrorPath: Path<T> | undefined;

	for (const graphqlError of error?.errors) {
		// Set form field errors if the GraphQL error has form field errors
		if (
			graphqlError.extensions.code === "BAD_USER_INPUT" &&
			graphqlError.extensions.errors?.issues
		) {
			for (const issue of graphqlError.extensions.errors.issues) {
				const path = issue.path.join(".") as Path<T>;

				form.setError(path, issueToError(issue, formErrorTranslations));
				if (!firstErrorPath) {
					firstErrorPath = path;
				}
			}
		} else {
			if (
				graphqlError.extensions.errorCode &&
				ignoreErrorCodes.includes(graphqlError.extensions.errorCode)
			) {
				continue;
			}
			// Set global form error otherwise
			form.setError(
				"root",
				issueToError(
					{
						code: "custom",
						path: [],
						message: graphqlError.extensions.code,
					},
					formErrorTranslations,
				),
			);
		}
	}
	// scroll and focus to first input field with error
	if (firstErrorPath) form.setFocus(firstErrorPath);
};
